import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';

import boardItemTypes from '@constants/boardItemTypes';
import ShareModalContext, { SHARETYPE } from '@context/Modal/share-modal-context';
import UserContext from '@context/User/user-context';
import useAlert, { TYPES } from '@hooks/useAlert/useAlert';
import PRODUCT_QUERY from '@graphql/VendorVariant/queries/get-product-modal.graphql';
import ExploreProductModalContext, { IExploreProductModal } from '@context/Modal/explore-product-modal-context';
import AddToBoardModalContext from '@context/Modal/add-to-board-modal-context';

/* Dynamically loading this prevents it from being used as shared js
  between explore and room/home which was breaking both pages */
const ExploreProductModalNoSSR = dynamic(
  () => import('@components/shared/modals/ProductModal/ExploreProductModal'),
  { ssr: false }
);

interface ExploreProductModalProviderProps {
  designerName?: string;
}

export default function ExploreProductModalProvider({
  children,
  designerName
}: PropsWithChildren<ExploreProductModalProviderProps>) {
  const [exploreProductModalState, setExploreProductModalState] =
    useState<IExploreProductModal | null>(null);
  const {
    user,
    savedVendorVariantIds,
  } = useContext(UserContext);
  const { open: openShareModal } = useContext(ShareModalContext);
  const { open: openSaveModal } = useContext(AddToBoardModalContext);
  const { error, data, loading } = useQuery(PRODUCT_QUERY, {
    variables: { id: exploreProductModalState?.initialVendorVariantId },
    skip: !exploreProductModalState,
  });

  const errorAlert = useAlert(TYPES.ERROR);

  useEffect(() => {
    if (error || (
      !!(exploreProductModalState?.initialVendorVariantId)
      && !loading
      && !(data?.vendorVariantById)
    )) {
      errorAlert('Failed to load product.', 3000);
      setExploreProductModalState(null);
    }
  }, [error, exploreProductModalState?.initialVendorVariantId, loading, data?.vendorVariantById]);

  const getBoardId = (userId: number) => {
    let boardId;

    if (exploreProductModalState!.sourceBoardId) {
      boardId = exploreProductModalState!.sourceBoardId.toString();
    }

    if (exploreProductModalState!.projectBoardCommission) {
      if (userId === exploreProductModalState!.projectBoardCommission.userId) {
        boardId = exploreProductModalState!.projectBoardCommission.boardId.toString();
      }
    }

    return boardId;
  };

  const handleShare = () => {
    openShareModal({
      modelId: exploreProductModalState!.initialVendorVariantId,
      shareType: SHARETYPE.PRODUCT,
      url: data?.vendorVariantById?.productUrl
    });
  };

  const handleSave = () => {
    const imageUrl = data?.vendorVariantById.images[0].uri;
    openSaveModal({
      saveItemId: Number(exploreProductModalState!.initialVendorVariantId),
      saveItemImageUrl: imageUrl,
      saveItemType: boardItemTypes.VENDOR_VARIANTS,
      source: 'product_modal',
    });
  };

  const [triggeredAddToCart, setTriggeredAddToCart] = useState(false);
  const onTriggeredAddToCart = (value: boolean) => {
    setTriggeredAddToCart(value);
  };

  const [isDoneAddingToCart, setIsDoneAddingToCart] = useState(false);
  const onDoneAddingToCart = (value: boolean) => {
    setIsDoneAddingToCart(value);
  };

  return (
    <ExploreProductModalContext.Provider
      value={{
        open: setExploreProductModalState,
        isClosed: exploreProductModalState === null,
        onTriggeredAddToCart,
        triggeredAddToCart,
        onDoneAddingToCart,
        isDoneAddingToCart
      }}
    >
      {exploreProductModalState && data?.vendorVariantById && (
        <ExploreProductModalNoSSR
          {...exploreProductModalState}
          onClose={() => setExploreProductModalState(null)}
          onSave={handleSave}
          onShare={handleShare}
          saved={ savedVendorVariantIds[exploreProductModalState.initialVendorVariantId] }
          vendorVariant={data?.vendorVariantById}
          boardId={user ? getBoardId(Number(user.id)) : undefined}
          onTriggeredAddToCart={onTriggeredAddToCart}
          triggeredAddToCartState={triggeredAddToCart}
          onDoneAddingToCart={onDoneAddingToCart}
          designerName={designerName}
        />
      )}
      {children}
    </ExploreProductModalContext.Provider>
  );
}
