import { ImageLoaderProps } from 'next/image';

import logger from './logger';

/* istanbul ignore file */

import env from './envs/env';

export interface ResizedImageOpts {
  blur?: number;
  colorize?: string;
  fit?: string;
  format?: string;
  fill?: string;
  height?: number | string;
  width?: number | string;
  quality?: number | string | undefined;
  halign?: 'left' | 'center' | 'right';
  valign?: 'top' | 'middle' | 'bottom';
  url?: string;
  smart?: boolean;
}

/*
  This library rewrites an image URL to use the thumbor service at havenly.com/images/
  For more info about thumbor URL options, see the docs at:
  https://github.com/thumbor/thumbor/wiki/Usage
*/
export function getResizedImageUrl(opts: ResizedImageOpts): string {
  const {
    // Set this to an integer to enable image blurring filter
    blur,

    // Set this to a colorize string to enable
    // Example 'redPercentage, greenPercentage, bluePercentage, fillColor' -- '35,40,50,79A8B2'
    colorize,

    // usually null or 'fit-in'. see docs for more options
    fit,

    format,

    // Fill color to fill in missing parts. Usually used with "fit-in" or "adaptive-fit-in"
    fill,

    height = '',

    // this low default quality assumes we are rendering 2x-size images
    quality = 50,

    halign,
    valign,

    url = '',

    width = '',

    smart,
  } = opts;

  // Don't mess with an image we're already serving through Thumbor
  if (url.startsWith(env.THUMBOR_URL!)) {
    return appendThumborTransformQueryString({
      url,
      width,
      height,
      quality,
    });
  }

  const sizes = `${width}x${height}`;

  let fullUrl = 'https://static.havenly.com/product/production/image-is-missing.jpg';
  if (url) fullUrl = url;

  if (fullUrl.match(/^\/\//) !== null) {
    fullUrl = `https:${fullUrl}`;
  }

  const urlPieces = [
    env.THUMBOR_URL?.replace(/\/*$/, ''), // remove trailing slashes
    'unsafe',
  ];

  const filterProps: any = {
    blur,
    colorize,
    format,
    quality,
    fill,
  };

  if (fit) urlPieces.push(fit!);

  urlPieces.push(sizes);

  if (smart) urlPieces.push('smart');

  if (halign) urlPieces.push(halign);
  if (valign) urlPieces.push(valign);

  let filters = 'filters';
  Object.keys(filterProps).forEach((filter) => {
    if (filterProps[filter] !== null && filterProps[filter] !== undefined) {
      filters += `:${filter}(${filterProps[filter]})`;
    }
  });

  // Only add filters if any were defined.
  if (filters !== 'filters') {
    urlPieces.push(filters);
  }

  urlPieces.push(fullUrl);

  return urlPieces.join('/');
}

export function imageLoader({ src, width, quality }: ImageLoaderProps): string {
  return getResizedImageUrl({
    format: 'webp',
    url: src,
    width: width.toString(),
    quality,
  });
}

/**
 * Thumbor transforms applied as a query string.
 * Image must be hosted on https://images[-dev].havenly.com/*
 * If additional transforms are needed, the image-proxy conf in the thumbor repo must be updated.
 */
export function appendThumborTransformQueryString({
  height = '',
  quality = '',
  url,
  width = '',
}: {
  height?: string | number;
  quality?: string | number;
  url: string;
  width?: string | number;
}) {
  const log = logger('appendThumborTransformQueryString');
  let queryParams: URLSearchParams;
  // if this is used in a list, don't break the page if a url is bad
  try {
    queryParams = new URLSearchParams((new URL(url)).searchParams);
  } catch (error: any) {
    log.error(error);
    return '';
  }

  if (height) queryParams.append('h', height.toString());
  if (quality) queryParams.append('q', quality.toString());
  if (width) queryParams.append('w', width.toString());

  return `${url}?${queryParams.toString()}`;
}

export class SrcSet {
  public static DEFAULT = 'default';

  private readonly imageUrls: {[key:string]: string} = {};

  private breakpoints: {[key:number] : number} = {};

  private _defaultWidth: number = 0;

  public constructor({ sizes, url, props }: {
    sizes: Map<string, number[]>;
    url: string;
    props?: any;
  }) {
    if (!sizes.size) {
      throw new Error('Invalid sizes array. Must not be empty.');
    }

    if (!Object.values(sizes).every((size) => size.length === 2)) {
      throw new Error(
        'Invalid sizes array. Every element must be an array of two elements [width,height]',
      );
    }

    if (!sizes.get(SrcSet.DEFAULT)) {
      throw new Error('No default size defined');
    }

    sizes.forEach(([width, height], breakpoint) => {
      this.imageUrls[`${width}w`] = getResizedImageUrl({
        ...props,
        url,
        width: `${width}`,
        height: height > 0 ? `${height}` : '',
      });

      if (breakpoint === SrcSet.DEFAULT) {
        this._defaultWidth = width;
      } else if (!/^\d+$/.test(breakpoint)) {
        throw new Error(`Invalid breakpoint: ${breakpoint}. Must be an int.`);
      } else {
        const breakpointWidth = parseInt(breakpoint, 10);
        this.breakpoints[breakpointWidth] = width;
      }
    });
  }

  public get defaultWidth(): number {
    return this._defaultWidth;
  }

  /**
   * Returns the url from the source set that matches the given width.
   * @param width Width of the image
   */
  public urlForWidth(width: number) {
    return this.imageUrls[`${width}w`];
  }

  public breakpointSizes(): string {
    let breakpointSizes = Object.entries(this.breakpoints).map(([breakpoint, width]) => {
      return `(max-width: ${breakpoint}px) ${width}px`;
    })
      .join(', ');

    if (breakpointSizes) {
      breakpointSizes += ', ';
    }
    breakpointSizes += `${this._defaultWidth}px`;

    return breakpointSizes;
  }

  /**
   * Outputs ths source set in a manner compatible for an <img/> tag.
   */
  public toSrcSet(): string {
    return Object.entries(this.imageUrls)
      .map(([width, url]) => `${url} ${width}`)
      .join(', ');
  }
}
